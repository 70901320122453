<template>
<section class="modal" :class="{'open':modalState}">
  <div class="overlay" @click="closeModal()"></div>
  <div class="modal-content">
    <slot :open="openModal" :close="closeModal"></slot>
  </div>
</section>
</template>

<script>
export default {
  name: "CustomModal",
  data(){
    return{
      modalState:false,
    }
  },
  methods:{
    openModal(){
      this.modalState = true;
    },
    closeModal(){
      this.modalState = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import "CustomModal";
</style>